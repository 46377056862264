import styled from "@emotion/styled";
import { PageProps, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import AppContainer from "../components/container";
import AppMarkdown from "../components/markdown";
import AppPageTitle from "../components/page-title";

const Content = styled.main(({ theme }) => ({
  padding: `0 ${theme.pagePadMobile}px`,
  marginBottom: theme.spacing(4),
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
  },
}));

const AppTerms = ({ data }: PageProps<GatsbyTypes.OfficeQuery>) => {
  return (
    <AppContainer
      flexDirection="column"
      alignItems="center"
      breakpoint="desktop"
    >
      <Helmet title="RWS | Betingelser"></Helmet>
      <AppPageTitle>{data.page?.frontmatter?.title}</AppPageTitle>
      <Content>
        <AppMarkdown content={data.page?.frontmatter?.content} />
      </Content>
    </AppContainer>
  );
};

export default AppTerms;

export const pageQuery = graphql`
  query Terms {
    page: markdownRemark(fileAbsolutePath: { regex: "/terms/" }) {
      frontmatter {
        title
        content
      }
    }
  }
`;
